var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "relative" }, [
    _c(
      "button",
      {
        staticClass:
          "relative flex w-full px-6 py-2 text-sm cursor-pointer whitespace-nowrap hover:bg-blue-100 focus:outline-none",
        on: { click: _vm.toggleFilter },
      },
      [_vm._v(" " + _vm._s(_vm.label) + " ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }