var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "relative", attrs: { "data-cy": "tableComponent" } },
    [
      _c(
        "div",
        [
          _vm.tableFilter.quickFilters.length > 0
            ? _c(
                "quick-filter-component",
                _vm._g(
                  {
                    staticClass: "mb-4",
                    attrs: {
                      "data-cy": "quickFilterComponent",
                      filters: _vm.tableFilter.quickFilters,
                      activeFilterId: _vm.tableFilter.activeQuickFilterId,
                    },
                  },
                  _vm.quickFilterEventListeners
                )
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex my-2" },
        [
          _c("div", { staticClass: "ml-2" }, [
            _c(
              "div",
              [
                _vm.tableFilter.filters.length > 0
                  ? _c(
                      "table-filter-component",
                      _vm._g(
                        { attrs: { filters: _vm.tableFilter.filters } },
                        _vm.filterEventListeners
                      )
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm.localStorageKey && _vm.canEditPaginatedRows
            ? _c("table-drop-down-component", {
                staticClass: "ml-2",
                attrs: {
                  itemValue: _vm.paginationItemsPerPage,
                  itemValues: _vm.paginationOptions,
                },
                on: { update: _vm.updatePaginationOption },
              })
            : _vm._e(),
          _c(
            "div",
            [_vm._t("settings-left", null, { dataCy: "settingLeftSlot" })],
            2
          ),
          _c("div", { staticClass: "ml-auto" }, [_vm._t("settings-right")], 2),
          _vm.canDownloadCSV
            ? _c(
                "div",
                { staticClass: "flex gap-2" },
                [
                  _c("button-component", { on: { click: _vm.downloadData } }, [
                    _c(
                      "div",
                      { staticClass: "flex items-center whitespace-nowrap" },
                      [
                        _c("icon-component", {
                          staticClass: "pr-2 fill-current",
                          attrs: { name: "download", width: 24, height: 24 },
                        }),
                        _vm._v(" Download CSV "),
                      ],
                      1
                    ),
                  ]),
                  _c("button-component", { on: { click: _vm.copyData } }, [
                    _c(
                      "div",
                      { staticClass: "flex items-center whitespace-nowrap" },
                      [
                        _vm.copyButtonText === "Table Copied"
                          ? _c("icon-component", {
                              staticClass: "pr-2",
                              attrs: {
                                name: "checkGreen",
                                fill: "green",
                                width: 24,
                                height: 24,
                              },
                            })
                          : _c("icon-component", {
                              staticClass: "pr-2 fill-current",
                              attrs: { name: "copy", width: 24, height: 24 },
                            }),
                        _vm._v(" " + _vm._s(_vm.copyButtonText) + " "),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.activeFilters.length
        ? _c("div", { staticClass: "mb-4 text-xs" }, [
            _c(
              "div",
              { staticClass: "flex flex-wrap gap-1" },
              _vm._l(_vm.activeFilters, function (filter, index) {
                return _c(
                  "button",
                  {
                    key: index,
                    staticClass:
                      "p-1 px-3 text-xs text-blue-500 border border-blue-500 rounded-full cursor-pointer whitespace-nowrap",
                    attrs: { "data-cy": "table-active-filter-button" },
                    on: {
                      click: function ($event) {
                        return _vm.clearActiveFilter(filter.id)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(filter.label))]),
                    _vm._v(" : "),
                    _c("span", { staticClass: "italic text-red-500" }, [
                      _vm._v(_vm._s(filter.valueString)),
                    ]),
                    _c(
                      "span",
                      { staticClass: "ml-3 font-semibold text-black" },
                      [_vm._v("X")]
                    ),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _c(
        "air-force-spinner-component",
        { attrs: { loaded: _vm.loaded } },
        [
          _vm.data.length
            ? _c(
                "div",
                { staticClass: "flex overflow-x-auto" },
                [
                  _vm.isSelectable
                    ? _c("row-selection-component", {
                        attrs: {
                          "data-cy": "tableRowSelectionComponent",
                          rowIndexes: _vm.rowIndexes,
                          compact: _vm.compact,
                        },
                        on: { rowChecked: _vm.onRowChecked },
                      })
                    : _vm._e(),
                  _vm.pinnedColumns && _vm.pinnedColumns.length
                    ? _c("base-table-component", {
                        staticClass: "flex-shrink-0 border-r pinned-table",
                        attrs: {
                          sortedData: _vm.data,
                          columns: _vm.pinnedColumns,
                          isSortable: _vm.isSortable,
                          isRowClickable: _vm.isRowClickable,
                          isAscendingSortOrder: _vm.isAscendingSortOrder,
                          sortColumn: _vm.sortKey,
                          compact: _vm.compact,
                          activeHoverRow: _vm.hoverRowIndex,
                        },
                        on: {
                          cellEvent: _vm.emitCellEvent,
                          rowClicked: _vm.emitRowClickedEvent,
                          sortData: _vm.sortData,
                          hoverRow: _vm.handleHoverRow,
                        },
                      })
                    : _vm._e(),
                  _c("base-table-component", {
                    staticClass: "w-full",
                    attrs: {
                      sortedData: _vm.data,
                      columns: _vm.columns,
                      isSortable: _vm.isSortable,
                      isRowClickable: _vm.isRowClickable,
                      tableConfiguration: _vm.tableConfiguration,
                      isAscendingSortOrder: _vm.isAscendingSortOrder,
                      sortColumn: _vm.sortKey,
                      isScrollable: true,
                      compact: _vm.compact,
                      activeHoverRow: _vm.hoverRowIndex,
                      columnDataTextWrapping: _vm.columnDataTextWrapping,
                    },
                    on: {
                      cellEvent: _vm.emitCellEvent,
                      rowClicked: _vm.emitRowClickedEvent,
                      sortData: _vm.sortData,
                      hoverRow: _vm.handleHoverRow,
                    },
                  }),
                ],
                1
              )
            : _c("empty-table-message-component", {
                attrs: { message: _vm.emptyTableMessage },
              }),
        ],
        1
      ),
      _c(
        "pagination-table-component",
        _vm._g(
          { attrs: { pages: _vm.pages, paginationIndex: _vm.paginationIndex } },
          _vm.$listeners
        )
      ),
      _vm.showSimpleTable
        ? _c("modal-component", [
            _c(
              "div",
              { ref: "simpleTable", staticClass: "p-4 overflow-auto h-96" },
              [
                _c("simple-table", {
                  attrs: { tableData: _vm.dataFormattedForCsv },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }