import { SearchDto, SearchSortDirection } from '@/models/Dtos/common/searchDto';
import { IQuickFilterFilter } from '@/models/Filters/QuickFilterFilter';
import { FilterTypes, ITableConfiguration } from '@/models/Table/Table';
import {
  DropDownOptions,
  paginationItemsPerPage as defaultPaginationOptions
} from '@/models/Table/TableOption';
import { RootState } from '@/store/types';
import { Store } from 'vuex';
import { TableConfigBuilder } from './TableConfigBuilder';
import { TableDataProvider } from './TableDataProvider';
import { FilterSubmitDto, TableSearchFilter } from './TableSearchFilter';

export class TableFilter<T> {
  constructor(
    public id: number,
    public label: string,
    public valueString: string,
    private applyFunc: (t: T, value: unknown) => void,
    public value: unknown
  ) {}

  apply(dto: T): void {
    this.applyFunc(dto, this.value);
  }
}

export interface FilterEventListeners {
  submitFilter: (filterSubmitDto: FilterSubmitDto) => void;
  clearFilter: (index: number) => void;
}

export interface QuickFilterEventListeners {
  clickFilter: (index: number) => void;
}

export interface TableProps<
  TableDataType,
  EntityType,
  SearchDtoType extends SearchDto<EntityType, unknown>
> {
  tableFilter: TableSearchFilter<EntityType, SearchDtoType>;
  filterEventListeners: FilterEventListeners;
  quickFilterEventListeners: QuickFilterEventListeners;
  defaultSortColumn: keyof EntityType;
  tableConfiguration: ITableConfiguration<TableDataType, EntityType, string>;
  data: TableDataType[];
  activeFilters: TableFilter<SearchDtoType>[];
  totalSearchResults: number;
  paginationIndex: number;
  paginationOptions: DropDownOptions[];
  paginationItemsPerPage: number;
  loaded: boolean;
}

export default class TableSearchService<
  TableDataType,
  EntityType,
  CustomSortType extends string,
  SearchDtoType extends SearchDto<EntityType, CustomSortType>
> {
  protected tableConfiguration: ITableConfiguration<
    TableDataType,
    EntityType,
    string
  >;
  protected dataProvider: TableDataProvider<
    TableDataType,
    EntityType,
    SearchDtoType
  >;
  protected searchDto: SearchDtoType;
  protected defaultSortProperty: keyof EntityType;
  public tableFilter: TableSearchFilter<EntityType, SearchDtoType>;

  protected store: Store<RootState>;
  // DATA
  public data: TableDataType[] = [];
  public resultCount = 0;
  public loaded = true;
  // PAGINATION
  public paginationIndex = 0;
  public paginationItemsPerPage: number;
  public paginationOptions: DropDownOptions[];
  // SORT
  public sortProperty?: keyof EntityType | CustomSortType;
  public isAscendingSortOrder = true;
  public selectedRowIndexes: number[] = [];

  constructor(
    store: Store<RootState>,
    dataProvider: TableDataProvider<TableDataType, EntityType, SearchDtoType>,
    searchDto: SearchDtoType,
    sortConfig: {
      defaultSortProperty: keyof EntityType;
      isAscendingSortOrder?: boolean;
    },
    tableConfigurationBuilder: TableConfigBuilder<
      TableDataType,
      EntityType,
      CustomSortType,
      SearchDtoType
    >,
    filterConfig: {
      filters: FilterTypes<SearchDtoType>[];
      quickFilters: IQuickFilterFilter<SearchDtoType>[];
    },
    paginationConfig?: {
      paginationOptions?: DropDownOptions[];
      initaliPaginationOptions?: DropDownOptions;
    }
  ) {
    this.store = store;
    this.dataProvider = dataProvider;
    this.searchDto = searchDto;
    this.tableConfiguration = tableConfigurationBuilder(this, store);
    this.tableFilter = new TableSearchFilter(
      filterConfig.filters,
      filterConfig.quickFilters
    );
    this.defaultSortProperty = sortConfig.defaultSortProperty;
    this.paginationOptions =
      paginationConfig?.paginationOptions &&
      paginationConfig.paginationOptions.length > 0
        ? paginationConfig.paginationOptions
        : defaultPaginationOptions;
    this.paginationItemsPerPage = this.paginationOptions[0].value;
    this.isAscendingSortOrder =
      sortConfig.isAscendingSortOrder !== undefined
        ? sortConfig.isAscendingSortOrder
        : true;
  }

  async queryData(): Promise<void> {
    this.loaded = false;
    const searchSortDirection = this.isAscendingSortOrder
      ? SearchSortDirection.ASCENDING
      : SearchSortDirection.DESCENDING;
    const dto: SearchDtoType = {
      ...this.searchDto,
      sortProperty: this.sortProperty ?? this.defaultSortProperty,
      sortDirection: searchSortDirection,
      limit: this.paginationItemsPerPage,
      offset: this.paginationIndex * this.paginationItemsPerPage
    };
    this.tableFilter.applyFilters(dto);
    const results = await this.dataProvider.searchHandler(dto);
    this.data = results.data;
    this.resultCount = results.resultCount;
    this.loaded = true;
  }

  async reset(): Promise<void> {
    this.paginationIndex = 0;
    return this.queryData();
  }

  applyFilters(dto: SearchDtoType): void {
    this.tableFilter.activeFilters.forEach((filter) => filter.apply(dto));
  }

  // Props and bindings

  get tableProps(): TableProps<TableDataType, EntityType, SearchDtoType> {
    return {
      tableFilter: this.tableFilter,
      filterEventListeners: this.filterEventListeners,
      quickFilterEventListeners: this.quickFilterEventListeners,
      defaultSortColumn: this.defaultSortProperty,
      tableConfiguration: this.tableConfiguration,
      data: this.data,
      activeFilters: this.tableFilter.activeFilters,
      totalSearchResults: this.resultCount,
      paginationIndex: this.paginationIndex,
      paginationOptions: this.paginationOptions,
      paginationItemsPerPage: this.paginationItemsPerPage,
      loaded: this.loaded
    };
  }

  get tableEventListeners() {
    return {
      clearActiveFilter: this.handleFilterClear.bind(this),
      updatePage: this.handleUpdatePage.bind(this),
      updatePaginationOption: this.handleUpdatePaginationOption.bind(this),
      sortData: this.handleSortUpdate.bind(this),
      selectedRows: this.handleRowChecked.bind(this)
    };
  }

  get filterEventListeners(): FilterEventListeners {
    return {
      submitFilter: this.handleFilterSubmit.bind(this),
      clearFilter: this.handleFilterClear.bind(this)
    };
  }

  get quickFilterEventListeners(): QuickFilterEventListeners {
    return {
      clickFilter: this.handleQuickFilterClick.bind(this)
    };
  }

  get selectedData(): TableDataType[] {
    return this.selectedRowIndexes.map((index) => this.data[index]);
  }

  get pages(): number {
    return Math.ceil(this.resultCount / this.paginationItemsPerPage);
  }

  // Event handlers

  async handleUpdatePage(paginationIndex: number): Promise<void> {
    if (paginationIndex < 0 || paginationIndex >= this.pages) {
      return;
    }

    this.paginationIndex = paginationIndex;
    return this.queryData();
  }

  handleUpdatePaginationOption(paginationOption: number): void {
    this.paginationItemsPerPage = paginationOption;
    this.paginationIndex = 0;
    this.queryData();
  }

  handleSortUpdate({
    sortKey,
    isAscendingSortOrder
  }: {
    sortKey: keyof EntityType | CustomSortType;
    isAscendingSortOrder: boolean;
  }): void {
    this.sortProperty = sortKey;
    this.isAscendingSortOrder = isAscendingSortOrder;
    this.reset();
  }

  handleFilterSubmit(filterSubmitDto: FilterSubmitDto): void {
    this.tableFilter.addFilter(filterSubmitDto);
    this.reset();
  }

  handleFilterClear(index: number): void {
    this.tableFilter.removeFilter(index);
    this.reset();
  }

  handleQuickFilterClick(index: number): void {
    this.tableFilter.addQuickFilter(index);
    this.reset();
  }

  handleRowChecked(selectedIndexes: number[]) {
    this.selectedRowIndexes = selectedIndexes;
  }
}
