import { SearchDto } from '@/models/Dtos/common/searchDto';
import { IQuickFilterFilter } from '@/models/Filters/QuickFilterFilter';
import { FilterTypes } from '@/models/Table/Table';
import { TableFilter } from './TableSearch.service';

export class FilterSubmitDto {
  constructor(public index: number, public value: unknown) {}
}

export class TableSearchFilter<
  EntityType,
  SearchDtoType extends SearchDto<EntityType, unknown>
> {
  public filters: FilterTypes<SearchDtoType>[];
  public activeFilters: TableFilter<SearchDtoType>[] = [];

  public quickFilters: IQuickFilterFilter<SearchDtoType>[];
  public activeQuickFilter?: IQuickFilterFilter<SearchDtoType>;

  get activeQuickFilterId() {
    if (this.activeQuickFilter) {
      return this.quickFilters.findIndex(
        (quickFilter) => quickFilter === this.activeQuickFilter
      );
    }
    return -1;
  }

  constructor(
    filters: FilterTypes<SearchDtoType>[],
    quickFilters: IQuickFilterFilter<SearchDtoType>[]
  ) {
    this.filters = filters;
    this.quickFilters = quickFilters;
  }

  applyFilters(searchDto: SearchDtoType): void {
    this.activeFilters.forEach((filter) => filter.apply(searchDto));
    if (this.activeQuickFilter) {
      this.activeQuickFilter.apply(searchDto);
    }
  }

  addFilter(filterSubmitDto: FilterSubmitDto): void {
    const index = filterSubmitDto.index;
    const filter = this.filters[index];
    const existingFilter = this.activeFilters.find(
      (activeFilter) => activeFilter.id === index
    );
    if (existingFilter) {
      this.removeFilter(existingFilter.id);
    }
    try {
      const value = filter.validator(filterSubmitDto.value);
      const valueString = filter.formatter(value as never);
      const tableFilter = new TableFilter(
        index,
        filter.label,
        valueString,
        filter.apply as (dto: SearchDtoType, value: unknown) => void,
        value
      );
      this.activeFilters.push(tableFilter);
    } catch (error: unknown) {
      console.error(error);
    }
  }

  removeFilter(index: number): void {
    this.activeFilters = this.activeFilters.filter((activeFilter) => {
      return activeFilter.id !== index;
    });
  }

  addQuickFilter(index: number): void {
    const newActiveQuickFilter = this.quickFilters[index];
    if (newActiveQuickFilter === this.activeQuickFilter) {
      this.activeQuickFilter = undefined;
      return;
    }
    this.activeQuickFilter = newActiveQuickFilter;
  }
}
