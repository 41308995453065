
import ActionMenu from '@/components/ActionMenus/ActionMenuComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Dropdown from '@/components/Forms/Elements/DropdownComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import NumberRangeComponent from '@/components/NumberRangeComponent.vue';
import { FilterTypes } from '@/models/Table/Table';
import clickOutside from '@/util/directives/clickOutside';
import { Component as VueComponent } from 'vue';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { FilterSubmitDto } from './models/TableSearchFilter';

@Component<TableFilterComponent>({
  components: {
    ActionMenu,
    ButtonComponent,
    Dropdown,
    NumberRangeComponent,
    TextInput,
    IconComponent
  },
  directives: {
    clickOutside
  }
})
export default class TableFilterComponent extends Vue {
  @Prop({
    default: () => {
      return [] as FilterTypes<unknown>[];
    }
  })
  filters!: FilterTypes<unknown>[];

  protected readonly INVALID_INDEX = -1;
  selectedFilterIndex: number = this.INVALID_INDEX;

  showMenu = false;

  getComponent(filter: FilterTypes<unknown>): VueComponent | undefined {
    return filter.component;
  }

  getComponentProps(filter: FilterTypes<unknown>) {
    return filter.componentProps;
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu;
    this.selectedFilterIndex = this.INVALID_INDEX;
  }

  handleSelectFilter(index: number): void {
    this.selectedFilterIndex = index;
  }

  @Emit('submitFilter')
  handleSubmit(value: unknown, index: number): FilterSubmitDto {
    this.close();
    return new FilterSubmitDto(index, value);
  }

  @Emit('clearFilter')
  handleClearFilter(index: number): number {
    return index;
  }

  close(): void {
    this.showMenu = false;
    this.selectedFilterIndex = this.INVALID_INDEX;
  }
}
