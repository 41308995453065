var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.columns && _vm.columns.length
    ? _c("table", { staticClass: "border border-collapse" }, [
        _c("caption", { staticClass: "hidden" }, [
          _vm._v(" a simple table component "),
        ]),
        _c("thead", [
          _c(
            "tr",
            _vm._l(_vm.columns, function (column, columnIndex) {
              return _c(
                "th",
                {
                  key: columnIndex,
                  staticClass: "p-1 text-left border whitespace-nowrap",
                  attrs: { scope: "col" },
                },
                [_vm._v(" " + _vm._s(column) + " ")]
              )
            }),
            0
          ),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.tableData, function (row, rowIndex) {
            return _c(
              "tr",
              { key: rowIndex },
              _vm._l(row, function (item, columnIndex) {
                return _c(
                  "td",
                  {
                    key: columnIndex,
                    staticClass: "p-1 border whitespace-nowrap",
                  },
                  [_vm._v(" " + _vm._s(item) + " ")]
                )
              }),
              0
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }