
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Component as VueComponent } from 'vue';
import {
  ColumnConfiguration,
  ITableConfiguration,
  TableRow
} from '@/models/Table/Table';
import IconComponent from '@/components/IconComponent.vue';
import { formatDate } from '@/services/formatService';

@Component<BaseTableComponent>({ components: { IconComponent } })
export default class BaseTableComponent extends Vue {
  @Prop({
    default: () => []
  })
  columns!: ColumnConfiguration<unknown, unknown, never>[];

  @Prop({
    default: false
  })
  isSortable!: boolean;

  @Prop()
  sortColumn?: string;

  @Prop({
    default: false
  })
  isSelectable!: boolean;

  @Prop({
    default: false
  })
  isRowClickable!: boolean;

  @Prop({
    default: false
  })
  isAscendingSortOrder!: boolean;

  @Prop({
    default: false
  })
  isScrollable!: boolean;

  @Prop()
  tableConfiguration!: ITableConfiguration<unknown, unknown, never>;

  @Prop({ default: false })
  compact!: boolean;

  @Prop({ default: false })
  columnDataTextWrapping!: boolean;

  @Prop({
    default: () => {
      return (item: string) => item;
    }
  })
  formatter!: (item: string) => unknown;

  @Prop({ default: () => [] })
  sortedData!: TableRow[];

  @Prop({ default: null })
  activeHoverRow!: number | null;

  sortData(column: ColumnConfiguration<unknown, unknown, never>): void {
    this.$emit('sortData', column.sortKey);
  }

  getRenderString(
    columnConfig: ColumnConfiguration<unknown, unknown, never>,
    rowData: TableRow
  ) {
    const key = columnConfig.render as unknown as keyof TableRow;
    const value = rowData[key];
    return this.format(value);
  }

  format(data: string | Date): string {
    if (data instanceof Date) {
      return formatDate(data);
    }
    return data;
  }

  getComponent(
    columnConfig: ColumnConfiguration<unknown, unknown, never>
  ): VueComponent | undefined {
    const columnRender = columnConfig.render;
    if (typeof columnRender === 'string') {
      return;
    }

    if ('componentRef' in columnRender) {
      return columnRender?.componentRef;
    }
  }

  getComponentProps(
    columnConfig: ColumnConfiguration<unknown, unknown, never>,
    rowDataObject: Record<string | number, unknown>
  ): unknown {
    const columnRender = columnConfig.render;
    if (typeof columnRender === 'string') {
      return;
    }
    if ('componentProps' in columnRender) {
      const props = columnRender?.componentProps?.(rowDataObject);
      return props;
    }
  }

  isColumnSortable(
    column: ColumnConfiguration<unknown, unknown, never>
  ): boolean {
    return !!column.sortKey ?? false;
  }

  emitRowClickedEvent(rowIndex: number): void {
    this.$emit('rowClicked', rowIndex);
  }

  emitCellEvent(columnIndex: number, rowIndex: number, payload: unknown): void {
    const column = this.columns[columnIndex];
    this.$emit('cellEvent', column, rowIndex, payload);
  }

  onHoverRow(rowIndex: number): void {
    this.$emit('hoverRow', rowIndex);
  }
}
