var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "flex flex-col items-center justify-center w-full p-32 font-semibold text-gray-400 border rounded",
    },
    [
      _c("icon-component", { attrs: { name: "logo", height: 60, width: 67 } }),
      _c("div", [_vm._v(_vm._s(_vm.message))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }