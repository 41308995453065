
import ActionMenu from '@/components/ActionMenus/ActionMenuComponent.vue';
import Dropdown from '@/components/Forms/Elements/DropdownComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import NumberRangeComponent from '@/components/NumberRangeComponent.vue';
import clickOutside from '@/util/directives/clickOutside';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component<BooleanFilterComponent>({
  components: {
    ActionMenu,
    Dropdown,
    NumberRangeComponent,
    TextInput,
    IconComponent
  },
  directives: {
    clickOutside
  }
})
export default class BooleanFilterComponent extends Vue {
  @Prop()
  label!: string;

  toggleFilter(): void {
    this.submit();
  }

  @Emit('toggleMenu')
  toggleMenu(): void {
    return;
  }

  @Emit('activeFilter')
  setActiveFilter(): void {
    return;
  }

  @Emit('submit')
  submit(): boolean {
    return true;
  }

  @Emit('clearFilter')
  clearFilter(): void {
    this.$emit('clearFilter');
  }

  @Emit('closeSubMenus')
  closeSubMenus(): void {
    return;
  }

  @Emit('closeAllMenus')
  closeAllMenus(): void {
    return;
  }
}
