
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import IconComponent from '@/components/IconComponent.vue';

@Component<AirForceSpinnerComponent>({
  components: {
    IconComponent
  }
})
export default class AirForceSpinnerComponent extends Vue {
  @Prop({ default: true })
  loaded!: boolean;
}
