
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColumnSelectionItem } from '@/models/Table/Table';
import IconComponent from '@/components/IconComponent.vue';

@Component<ColumnSelectionComponent>({ components: { IconComponent } })
export default class ColumnSelectionComponent extends Vue {
  @Prop({ default: () => [] })
  columns?: ColumnSelectionItem[];

  @Prop({
    default: () => {
      return (item: string) => item;
    }
  })
  formatter?: () => unknown;

  private dragColumn: ColumnSelectionItem | null = null;
  private isDragging = false;

  /* Set the current column being dragged */
  onDragStart(evt: Event, column: ColumnSelectionItem) {
    this.dragColumn = column;
    this.isDragging = true;
  }
  /* prvent default to allow for the onDrop event to work */
  onDragOver(evt: Event) {
    evt.preventDefault();
  }
  /* Reset dragging and drag column */
  onDragEnd() {
    this.dragColumn = null;
    this.isDragging = false;
  }
  /* Dropped element */
  onDrop(evt: Event, column: ColumnSelectionItem) {
    evt.preventDefault();
    if (!this.columns || !this.isDragging) return;
    if (
      this.dragColumn &&
      this.columnExists(this.dragColumn) &&
      this.columnExists(column)
    ) {
      this.swapColumn(this.dragColumn, column);
    }
    this.dragColumn = null;
  }
  columnExists(column: ColumnSelectionItem): boolean {
    if (!this.columns) return false;
    return this.columns?.findIndex((c) => c === column) > -1;
  }
  /* Pin or un-pin a column */
  togglePinnedColumn(column: ColumnSelectionItem) {
    this.$emit('togglePinnedColumn', column);
  }
  swapColumn(
    srcColumn: ColumnSelectionItem,
    targetColumn: ColumnSelectionItem
  ) {
    this.$emit('swapColumns', srcColumn, targetColumn);
  }
  selectColumn(index: number, checked: boolean) {
    this.$emit('selectColumn', index, checked);
  }
}
