var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.inputData,
          expression: "inputData",
        },
      ],
      staticClass:
        "flex max-w-full min-h-full border border-gray-200 rounded focus:outline-outlined",
      attrs: { list: "pagelist", placeholder: _vm.placeHolderText },
      domProps: { value: _vm.inputData },
      on: {
        keypress: function ($event) {
          ;[_vm.isNumberOnly ? _vm.isNumber($event) : ""]
        },
        keyup: function ($event) {
          return _vm.onEnterOrSelect($event, _vm.inputData)
        },
        input: function ($event) {
          if ($event.target.composing) return
          _vm.inputData = $event.target.value
        },
      },
    }),
    _c(
      "datalist",
      { attrs: { id: "pagelist" } },
      _vm._l(_vm.items, function (item) {
        return _c("option", { key: item, domProps: { value: item } }, [
          _vm._v(" " + _vm._s(item) + " "),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }