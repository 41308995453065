
// Mixins
import PaginationTableComponent from './PaginationTableComponent.vue';
import AirForceSpinnerComponent from '@/components/AirForceSpinnerComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import ModalComponent from '@/components/Modals/ModalComponent.vue';
import SlideOutComponent from '@/components/SlideOutComponent.vue';
import BaseTableComponent from '@/components/Table/BaseTableComponent.vue';
import ColumnSelectionComponent from '@/components/Table/ColumnSelectionComponent.vue';
import TableDropDownComponent from '@/components/Table/Elements/TableDropDownComponent.vue';
import EmptyTableMessageComponent from '@/components/Table/EmptyTableMessageComponent.vue';
import QuickFilterComponent from '@/components/Table/QuickFilterComponent.vue';
import RowSelectionComponent from '@/components/Table/RowSelectionComponent.vue';
import SimpleTable from '@/components/Table/SimpleTableComponent.vue';
import TableFilterComponent from '@/components/Table/TableFilterComponent.vue';
import { SearchDto } from '@/models/Dtos/common/searchDto';
import {
  ColumnConfiguration,
  ITableConfiguration,
  TableRow
} from '@/models/Table/Table';
import { DropDownOptions } from '@/models/Table/TableOption';
import clickOutside from '@/util/directives/clickOutside';
import { downloadCSV, selectElementContents } from '@/util/download-csv';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  FilterEventListeners,
  QuickFilterEventListeners,
  TableFilter
} from './models/TableSearch.service';
import { TableSearchFilter } from './models/TableSearchFilter';

@Component<AsyncTableComponent>({
  components: {
    BaseTableComponent,
    ButtonComponent,
    ColumnSelectionComponent,
    EmptyTableMessageComponent,
    ModalComponent,
    RowSelectionComponent,
    SimpleTable,
    SlideOutComponent,
    IconComponent,
    TableDropDownComponent,
    AirForceSpinnerComponent,
    TableFilterComponent,
    QuickFilterComponent,
    PaginationTableComponent
  },
  directives: {
    clickOutside
  }
})

// TODO add click handler for table configuration button above in view
export default class AsyncTableComponent extends Vue {
  @Prop({ default: [] })
  data!: TableRow[];

  @Prop()
  tableFilter!: TableSearchFilter<unknown, SearchDto<unknown, unknown>>;

  @Prop()
  quickFilterEventListeners!: QuickFilterEventListeners;

  @Prop()
  filterEventListeners!: FilterEventListeners;

  @Prop({
    default: false
  })
  isSortable!: boolean;

  @Prop()
  tableConfiguration!: ITableConfiguration<unknown, unknown, never>;

  @Prop()
  localStorageKey?: string;

  @Prop()
  emptyTableMessage?: string;

  @Prop({
    default: false
  })
  isSelectable!: boolean;

  @Prop({
    default: false
  })
  isRowClickable!: boolean;

  @Prop({
    default: false
  })
  canDownloadCSV!: boolean;

  @Prop({
    default: ''
  })
  csvFileName!: string;

  @Prop({ required: true })
  loaded!: boolean;

  @Prop({
    default: false
  })
  compact!: boolean;

  @Prop({ default: false })
  columnDataTextWrapping!: boolean;

  @Prop()
  paginationIndex!: number;

  //Customization for tables that need a different set of options
  @Prop()
  paginationOptions!: DropDownOptions[];

  @Prop({ default: true })
  canEditPaginatedRows!: boolean;

  @Prop()
  totalSearchResults!: number;

  @Prop({ default: [] })
  activeFilters!: TableFilter<unknown>[];

  @Prop()
  paginationItemsPerPage!: number;

  selectedRows: TableRow[] = [];
  sortKey: string | null = null;
  isAscendingSortOrder = true;

  copyButtonText = 'Copy Table';

  showSimpleTable = false;
  SNACK_BAR_TIMEOUT = 3000;
  hoverRowIndex: number | null = null;

  $refs!: {
    simpleTable: SimpleTable;
  };

  @Emit('clearActiveFilter')
  clearActiveFilter(filterId: number): number {
    return filterId;
  }

  get pinnedColumns(): ColumnConfiguration<unknown, unknown, never>[] {
    return this.allColumns.filter((column) => !!column.defaultPinned);
  }

  getRowFromEvent(rowIndex: number): TableRow {
    const indexOffset = this.paginationItemsPerPage * this.paginationIndex;
    const index = indexOffset + rowIndex;
    return this.data[index];
  }

  /* Cell click handler - re-emit */
  emitCellEvent(column: string, rowIndex: number, payload: unknown): void {
    this.$emit('cellEvent', column, this.getRowFromEvent(rowIndex), payload);
  }

  /* Cell click handler - re-emit */
  emitRowClickedEvent(rowIndex: number): void {
    this.$emit('rowClicked', this.getRowFromEvent(rowIndex));
  }
  /* Handler for row selection */
  onRowChecked(selectedRowIndexes: number[]): void {
    this.emitRowChecked(selectedRowIndexes);
  }

  @Emit('selectedRows')
  emitRowChecked(selectedRowIndexes: number[]): number[] {
    return selectedRowIndexes;
  }

  /* Changing the sortColumn value or the isAscendingSortOrder will update the computed columns */
  sortData(sortKey: string): void {
    if (sortKey === this.sortKey) {
      this.isAscendingSortOrder = !this.isAscendingSortOrder;
    }
    this.sortKey = sortKey;
    this.$emit('sortData', {
      sortKey,
      isAscendingSortOrder: this.isAscendingSortOrder
    });
    this.toPage(0);
  }

  /* Converts and downloads a CSV file of the Table data as reflected on the UI */
  downloadData(): void {
    const fileName = this.csvFileName
      ? this.csvFileName + `-${new Date().toLocaleString()}`
      : `Skypatch-${new Date().toLocaleString()}`;
    downloadCSV(this.dataFormattedForCsv, fileName);
  }
  /* Adds the table data to the clipboard */
  async copyData(): Promise<void> {
    this.showSimpleTable = true;
    try {
      await this.$nextTick(); // must wait for next digest cycle to get a handle on the DOM element
      const element = this.$refs.simpleTable;
      selectElementContents(element);
      this.copyButtonText = 'Table Copied';
      setTimeout(() => {
        this.copyButtonText = 'Copy Table';
      }, 5000);
      this.showSimpleTable = false;
    } catch (err: unknown) {
      this.showSimpleTable = false;
    }
  }

  @Emit('updatePage')
  updatePage(index: number): number {
    return index;
  }

  /* Next pagination page */
  nextPage(): void {
    this.updatePage(this.paginationIndex + 1);
  }

  /* Previus pagination page */
  previousPage(): void {
    this.updatePage(this.paginationIndex - 1);
  }

  /* To pagination page by index */
  toPage(index: number): void {
    this.updatePage(index);
  }

  /* Number of paginated pages */
  get pages(): number {
    return Math.ceil(this.totalSearchResults / this.paginationItemsPerPage);
  }

  /* Get the current row indexes, this could change depending on row filtering */
  get rowIndexes(): number[] {
    return this.data.map((row, index) => {
      return index;
    });
  }
  /* Helper for all columns, regardless if filtered */
  get columns(): ColumnConfiguration<unknown, unknown, never>[] {
    return this.allColumns.filter((column) => !column.defaultPinned);
  }

  get allColumns(): ColumnConfiguration<unknown, unknown, never>[] {
    return this.tableConfiguration.columnConfigurations;
  }

  /* data but with hidden columns removed. used for downloading CSV and copying to clipboard */
  get dataFormattedForCsv(): TableRow[] {
    return this.data;
  }
  /* Row data that are reactive to changes to columns */
  get rows(): TableRow[] {
    return this.data;
  }

  /*******************/
  /* Table Drop Down */
  /*******************/
  @Emit('updatePaginationOption')
  updatePaginationOption(val: number): number {
    return val;
  }

  get filterDropDownDefault(): string | null {
    const findDefault = this.paginationOptions.find((item) => item.default);
    return findDefault ? findDefault.label : null;
  }

  /****************************************************/

  dispatchSnackbar(message: string): any {
    this.$store.dispatch('snackBarModule/enqueue', {
      message,
      timeout: this.SNACK_BAR_TIMEOUT
    });
  }

  handleHoverRow(rowIndex: number): void {
    this.hoverRowIndex = rowIndex;
  }
}
