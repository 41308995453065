
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TableRow } from '@/models/Table/Legacy/LegacyTable';

@Component<SimpleTableComponent>({})
export default class SimpleTableComponent extends Vue {
  @Prop({
    default: () => []
  })
  tableData?: TableRow[];

  get columns() {
    if (!this.tableData || !this.tableData.length) return [];
    return Object.keys(this.tableData[0]);
  }
}
