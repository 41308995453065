
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconComponent from '@/components/IconComponent.vue';
import { DropDownOptions, TableOptionValue } from '@/models/Table/TableOption';

@Component<TableDropDownComponent>({
  components: { IconComponent }
})
export default class TableDropDownComponent extends Vue {
  @Prop()
  itemValue!: TableOptionValue;

  @Prop({ default: () => [] })
  itemValues!: DropDownOptions[];

  @Prop({
    default: 'Select One'
  })
  placeholder?: string;

  open = false;

  setDropdownValue(newVal: number | string): void {
    this.open = false;
    this.$emit('update', newVal);
  }

  toggle(): void {
    this.open = !this.open;
  }

  onBlur(): void {
    this.open = false;
  }

  get selected(): string {
    return typeof this.itemValue === 'number'
      ? this.itemValues.find((elem) => elem.value === this.itemValue)?.label ||
          '10 per page'
      : this.itemValue || '';
  }
}
