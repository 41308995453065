
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<AutoCompleteInputComponent>({
  components: {}
})
export default class AutoCompleteInputComponent extends Vue {
  @Prop()
  items!: number;

  @Prop()
  placeHolderText!: string;

  @Prop({ default: false })
  isNumberOnly!: boolean;

  inputData = '';

  onEnterOrSelect(evt: KeyboardEvent, value: string): void {
    if (!evt.key || evt.key === 'Enter') {
      if (+value - 1 > -1 && +value - 1 < this.items) {
        this.inputData = '';
        this.$emit('selected', value);
      }
    }
  }

  isNumber(evt: KeyboardEvent): void {
    if (!evt.code.includes('Digit')) {
      evt.preventDefault();
    }
  }
}
