
import ActionMenu from '@/components/ActionMenus/ActionMenuComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Dropdown from '@/components/Forms/Elements/DropdownComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import NumberRangeComponent from '@/components/NumberRangeComponent.vue';
import clickOutside from '@/util/directives/clickOutside';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component<StringFilterComponent>({
  components: {
    ActionMenu,
    ButtonComponent,
    Dropdown,
    NumberRangeComponent,
    TextInput,
    IconComponent
  },
  directives: {
    clickOutside
  }
})
export default class StringFilterComponent extends Vue {
  @Prop()
  label!: string;

  @Prop({ default: false })
  showSubMenu!: string;

  valueString = '';

  @Emit('selectFilter')
  selectFilter(): void {
    return;
  }

  @Emit('clearFilter')
  clearFilter(): void {
    return;
  }

  @Emit('submit')
  submit(): string {
    return this.valueString;
  }
}
