
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component<RowSelectionComponent>({})
export default class RowSelectionComponent extends Vue {
  @Prop({ default: () => [] })
  rowIndexes!: number[];

  @Prop({ default: false })
  compact?: boolean;

  selectedRowIndexes: number[] = [];

  get allRowsChecked() {
    return this.rowIndexes.every((index) =>
      this.selectedRowIndexes.includes(index)
    );
  }

  onRowChecked(isChecked: boolean, rowIndex: number) {
    if (isChecked) {
      this.selectedRowIndexes.push(rowIndex);
    } else {
      for (let i = 0; i < this.selectedRowIndexes.length; i++) {
        if (this.selectedRowIndexes[i] == rowIndex) {
          this.selectedRowIndexes.splice(i, 1);
          break;
        }
      }
    }
    this.emitRowChecked();
  }

  onCheckAllRows(isChecked: boolean) {
    if (isChecked) {
      this.selectedRowIndexes = this.rowIndexes;
    } else {
      this.selectedRowIndexes = [];
    }
    this.emitRowChecked();
  }

  @Emit('rowChecked')
  emitRowChecked(): number[] {
    return this.selectedRowIndexes;
  }
}
