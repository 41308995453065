var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "border rounded" }, [
    _c("table", { staticClass: "text-xs text-left" }, [
      _c("caption", { staticClass: "hidden" }, [
        _vm._v(" a generic table sub-component "),
      ]),
      _c("thead", [
        _c("tr", { staticClass: "border-b" }, [
          _c(
            "th",
            {
              staticClass: "px-6 font-normal whitespace-nowrap text-gray-4",
              class: { "h-8": _vm.compact, "h-12": !_vm.compact },
              attrs: { scope: "col" },
            },
            [
              _c("input", {
                staticClass: "table-cell align-middle",
                attrs: {
                  "data-cy": "tableRowSelectionCheckBoxAll",
                  type: "checkbox",
                },
                domProps: { checked: _vm.allRowsChecked },
                on: {
                  change: function ($event) {
                    return _vm.onCheckAllRows($event.target.checked)
                  },
                },
              }),
              _c("div", { staticClass: "border-b border-transparent" }),
            ]
          ),
        ]),
      ]),
      _c(
        "tbody",
        _vm._l(_vm.rowIndexes, function (rowIndex) {
          return _c(
            "tr",
            { key: rowIndex, staticClass: "border-b last:border-b-0" },
            [
              _c(
                "td",
                {
                  staticClass: "px-6 whitespace-nowrap",
                  class: { "h-8": _vm.compact, "h-12": !_vm.compact },
                },
                [
                  _c("input", {
                    staticClass: "table-cell align-middle",
                    attrs: {
                      "data-cy": "tableRowSelectionCheckBox",
                      type: "checkbox",
                    },
                    domProps: {
                      value: rowIndex,
                      checked: _vm.selectedRowIndexes.includes(rowIndex),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onRowChecked($event.target.checked, rowIndex)
                      },
                    },
                  }),
                ]
              ),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }