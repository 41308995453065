var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.pages > 1
    ? _c(
        "div",
        { staticClass: "flex items-center justify-end mt-4 space-x-2 text-sm" },
        [
          _vm.pages > 5
            ? _c(
                "button",
                {
                  staticClass:
                    "w-8 h-8 border border-transparent rounded disabled:transform-none disabled:text-black disabled:cursor-default disabled:bg-val-greyed-out hover:bg-tyler-hover-highlight active:bg-val-button-blue place-content-center bg-val-highlight focus:outline-none",
                  attrs: { disabled: _vm.onFirstPage() },
                  on: {
                    click: function ($event) {
                      return _vm.updatePage(0)
                    },
                  },
                },
                [
                  _c("icon-component", {
                    staticClass: "w-8 h-8 px-1 py-1",
                    class: [
                      _vm.onFirstPage()
                        ? "text-val-greyed-out-arrow stroke-current"
                        : "active:text-white active:stroke-2 active:stroke-current",
                    ],
                    attrs: {
                      name: "caretDoubleLeft",
                      height: 12,
                      width: 12,
                      stroke: "#3E66FB",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "button",
            {
              staticClass:
                "w-8 h-8 border border-transparent rounded disabled:transform-none disabled:text-black disabled:cursor-default disabled:bg-val-greyed-out hover:bg-tyler-hover-highlight active:bg-val-button-blue place-content-center bg-val-highlight focus:outline-none",
              attrs: { disabled: _vm.onFirstPage() },
              on: {
                click: function ($event) {
                  return _vm.previousPage()
                },
              },
            },
            [
              _c("icon-component", {
                staticClass: "w-8 h-8 px-1 py-1",
                class: [
                  _vm.paginationIndex === 0
                    ? "text-val-greyed-out-arrow stroke-current"
                    : "active:text-white active:stroke-2 active:stroke-current",
                ],
                attrs: {
                  name: "caretLeft",
                  height: 20,
                  width: 24,
                  stroke: "#3E66FB",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex overflow-x-auto" },
            _vm._l(_vm.numberOfTabs(_vm.pages), function (page, index) {
              return _c(
                "button",
                {
                  key: index + _vm.addNumber(_vm.paginationIndex, _vm.pages),
                  staticClass:
                    "w-8 h-8 font-semibold border border-transparent rounded font-overpass active:bg-val-button-blue active:text-white hover:bg-tyler-hover-highlight place-content-center focus:outline-none",
                  class: [
                    _vm.onCurrentPage(index)
                      ? "border-val-button-blue text-val-button-blue"
                      : "text-val-dark",
                  ],
                  on: {
                    click: function ($event) {
                      _vm.updatePage(
                        index + _vm.addNumber(_vm.paginationIndex, _vm.pages)
                      )
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        page + _vm.addNumber(_vm.paginationIndex, _vm.pages)
                      ) +
                      " "
                  ),
                ]
              )
            }),
            0
          ),
          _c(
            "button",
            {
              staticClass:
                "w-8 h-8 border border-transparent rounded disabled:transform-none disabled:text-black disabled:cursor-default disabled:bg-val-greyed-out hover:bg-tyler-hover-highlight active:bg-val-button-blue place-content-center bg-val-highlight focus:outline-none",
              attrs: { disabled: _vm.onLastPage() },
              on: {
                click: function ($event) {
                  return _vm.nextPage()
                },
              },
            },
            [
              _c("icon-component", {
                staticClass: "w-8 h-8 px-1 py-1",
                class: [
                  _vm.onLastPage()
                    ? "text-val-greyed-out-arrow stroke-current"
                    : "active:text-white active:stroke-2 active:stroke-current",
                ],
                attrs: {
                  name: "caretRight",
                  height: 24,
                  width: 24,
                  stroke: "#3E66FB",
                },
              }),
            ],
            1
          ),
          _vm.pages > 5
            ? _c(
                "button",
                {
                  staticClass:
                    "w-8 h-8 border border-transparent rounded disabled:transform-none disabled:text-black disabled:cursor-default disabled:bg-val-greyed-out hover:bg-tyler-hover-highlight active:bg-val-button-blue place-content-center bg-val-highlight focus:outline-none",
                  attrs: { disabled: _vm.onLastPage() },
                  on: {
                    click: function ($event) {
                      return _vm.updatePage(_vm.pages - 1)
                    },
                  },
                },
                [
                  _c("icon-component", {
                    staticClass: "w-8 h-8 px-1 py-1",
                    class: [
                      _vm.onLastPage()
                        ? "text-val-greyed-out-arrow stroke-current"
                        : "active:text-white active:stroke-2 active:stroke-current",
                    ],
                    attrs: {
                      name: "caretDoubleRight",
                      height: 24,
                      width: 24,
                      stroke: "#3E66FB",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.pages > 5
            ? _c(
                "div",
                [
                  _c("auto-complete-input-component", {
                    staticClass: "w-16 h-8",
                    attrs: {
                      items: _vm.pages,
                      isNumberOnly: true,
                      placeHolderText: "Pg #",
                    },
                    on: { selected: _vm.selectedPage },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.pages > 5
            ? _c(
                "label",
                { staticClass: "font-semibold normal-case text-val-dark" },
                [_vm._v("of " + _vm._s(_vm.pages) + " ")]
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }