var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "border rounded",
      class: { "overflow-x-auto": _vm.isScrollable },
      attrs: { "data-cy": "baseTableComponent" },
    },
    [
      _vm.columns && _vm.columns.length
        ? _c("table", { staticClass: "w-full text-xs text-left" }, [
            _c("caption", { staticClass: "hidden" }, [
              _vm._v(
                " a generic table for showing any data that gets pumped into it "
              ),
            ]),
            _c("thead", [
              _c(
                "tr",
                { staticClass: "border-b" },
                _vm._l(_vm.columns, function (column, columnIndex) {
                  return _c(
                    "th",
                    {
                      key: columnIndex,
                      staticClass:
                        "px-6 font-normal whitespace-nowrap text-gray-4",
                      class: { "h-8": _vm.compact, "h-12": !_vm.compact },
                      attrs: { scope: "col" },
                    },
                    [
                      _c("div", { staticClass: "align-middle" }, [
                        _vm.isSortable && _vm.isColumnSortable(column)
                          ? _c(
                              "button",
                              {
                                staticClass: "flex focus:outline-none",
                                on: {
                                  click: function ($event) {
                                    return _vm.sortData(column)
                                  },
                                },
                              },
                              [
                                _vm.sortColumn === column.sortKey
                                  ? _c(
                                      "div",
                                      { staticClass: "mr-2" },
                                      [
                                        _vm.isAscendingSortOrder
                                          ? _c("icon-component", {
                                              attrs: {
                                                name: "arrowUpFilter",
                                                width: 7,
                                                height: 14,
                                              },
                                            })
                                          : _c("icon-component", {
                                              attrs: {
                                                name: "arrowDownFilter",
                                                width: 7,
                                                height: 14,
                                              },
                                            }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "border-b border-transparent",
                                    class: {
                                      "border-b":
                                        _vm.sortColumn === column.columnTitle,
                                      "border-black":
                                        _vm.sortColumn === column.columnTitle,
                                      "text-black":
                                        _vm.sortColumn === column.columnTitle,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(column.columnTitle) + " "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _c("div", [
                              _vm._v(" " + _vm._s(column.columnTitle) + " "),
                            ]),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.sortedData, function (row, rowIndex) {
                return _c(
                  "tr",
                  {
                    key: rowIndex,
                    staticClass: "border-b last:border-b-0",
                    class: {
                      "cursor-pointer": _vm.isRowClickable,
                      "bg-blue-100":
                        _vm.activeHoverRow === rowIndex && _vm.isRowClickable,
                    },
                    on: {
                      mouseover: function ($event) {
                        return _vm.onHoverRow(rowIndex)
                      },
                      click: function ($event) {
                        return _vm.emitRowClickedEvent(rowIndex)
                      },
                    },
                  },
                  _vm._l(_vm.columns, function (column, columnIndex) {
                    return _c(
                      "td",
                      {
                        key: columnIndex,
                        staticClass: "px-6 whitespace-nowrap",
                        class: {
                          "h-8": _vm.compact,
                          "h-12": !_vm.compact,
                          "whitespace-pre-line": _vm.columnDataTextWrapping,
                          "whitespace-nowrap": !_vm.columnDataTextWrapping,
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex align-middle" },
                          [
                            _vm.getComponent(column)
                              ? _c(
                                  _vm.getComponent(column),
                                  _vm._b(
                                    {
                                      tag: "component",
                                      attrs: {
                                        value: row[columnIndex],
                                        rowDataObject: _vm.sortedData[rowIndex],
                                      },
                                      on: {
                                        cellEvent: function ($event) {
                                          return _vm.emitCellEvent(
                                            columnIndex,
                                            rowIndex,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    "component",
                                    // bind props
                                    _vm.getComponentProps(column, row),
                                    false
                                  )
                                )
                              : _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getRenderString(column, row)) +
                                      " "
                                  ),
                                ]),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }