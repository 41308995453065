
import ActionMenu from '@/components/ActionMenus/ActionMenuComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import { RadioOptions } from '@/models/Forms/FormOption';
import { DropDownOptions } from '@/models/Table/TableOption';
import clickOutside from '@/util/directives/clickOutside';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component<EnumFilterComponent>({
  components: {
    ActionMenu,
    ButtonComponent,
    DropdownComponent
  },
  directives: {
    clickOutside
  }
})
export default class EnumFilterComponent extends Vue {
  @Prop()
  label!: string;

  @Prop()
  enumValues!: DropDownOptions[] | RadioOptions[];

  @Prop({ default: false })
  showSubMenu!: string;

  valueString = '';

  @Emit('selectFilter')
  selectFilter(): void {
    return;
  }

  @Emit('clearFilter')
  clearFilter(): void {
    return;
  }

  @Emit('submit')
  submit(): string {
    return this.valueString;
  }
}
