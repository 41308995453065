
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import ButtonComponent from '../ButtonComponent.vue';
import IconComponent from '../IconComponent.vue';
import AutoCompleteInputComponent from '../AutoCompleteInputComponent.vue';

@Component<PaginationTableComponent>({
  components: {
    ButtonComponent,
    IconComponent,
    AutoCompleteInputComponent
  }
})
export default class PaginationTableComponent extends Vue {
  @Prop()
  paginationIndex!: number;

  @Prop()
  pages!: number;

  onFirstPage(): boolean {
    return this.paginationIndex === 0;
  }

  onLastPage(): boolean {
    return this.paginationIndex === this.pages - 1;
  }

  /* Logic to determine if a page should be highlighted as selected depending on it's location relative to total pages */
  onCurrentPage(index: number): boolean {
    return (
      this.paginationIndex ===
      index + this.addNumber(this.paginationIndex, this.pages)
    );
  }

  /* Adds a number depending on where the index is relative to page count */
  addNumber(paginationIndex: number, pages: number): number {
    if (paginationIndex < 3 || pages < 6) {
      return 0;
    } else if (paginationIndex > pages - 4) {
      return pages - 5;
    } else {
      return paginationIndex - 2;
    }
  }

  numberOfTabs(pages: number): number {
    return pages < 6 ? pages : 5;
  }
  /* Next pagination page */
  nextPage(): void {
    this.updatePage(this.paginationIndex + 1);
  }

  /* Previus pagination page */
  previousPage(): void {
    this.updatePage(this.paginationIndex - 1);
  }

  selectedPage(index: string): void {
    this.updatePage(+index - 1);
  }

  @Emit('updatePage')
  updatePage(index: number): number {
    return index;
  }
}
