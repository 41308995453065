/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue';
import StringFilterComponent from '@/components/Filters/StringFilterComponent.vue';
import BooleanFilterComponent from '@/components/Filters/BooleanFilterComponent.vue';
import EnumFilterComponent from '@/components/Filters/EnumFilterComponent.vue';
import DateFilterComponent from '@/components/Filters/DateFilterComponent.vue';
import { NumberRange } from '../NumberRange';
import NumberRangeFilterComponent from '@/components/Filters/NumberRangeFilterComponent.vue';
import { getInputOptions } from '@/util/getInputOptions';
import { DropDownOptions } from './TableOption';
import { RadioOptions } from '../Forms/FormOption';

export interface TableRow {
  [key: string]: any;
}

export interface TableComponentType<TableDataType> {
  componentRef: Component;
  componentProps?: (rowData: TableDataType) => Record<string, unknown>;
}

export type ColumnConfiguration<
  TableDataType,
  EntityType,
  CustomSortType extends string
> = {
  columnTitle: string;
  render: keyof TableDataType | TableComponentType<TableDataType>;
  sortKey?: keyof EntityType | CustomSortType;
  defaultPinned?: boolean;
};

export interface ITableConfiguration<
  TableDataType,
  EntityType,
  CustomSortType extends string
> {
  columnConfigurations: ColumnConfiguration<
    TableDataType,
    EntityType,
    CustomSortType
  >[];
}

export interface ColumnSelectionItem {
  isPinned?: boolean;
  isSelected?: boolean;
  name?: string;
}

export type ColumnOrder<T> = {
  pinned?: (keyof T extends string ? keyof T : string)[];
  unpinned: (keyof T extends string ? keyof T : string)[];
};

export interface IFilter<T, K, PropType extends IBaseFilterProps> {
  label: string;
  apply: (t: T, value: K) => void;
  formatter: (value: K) => string;
  validator: (dto: unknown) => K;
  component: Component;
  componentProps: PropType;
}

export interface IBaseFilterProps {
  label: string;
}

export interface INumberRangeComponentProps extends IBaseFilterProps {
  min: number;
  max: number;
}

export interface IEnumComponentProps extends IBaseFilterProps {
  enumValues: DropDownOptions[] | RadioOptions[];
}

export const stringFilterComponentDataBuilder = <T>(
  label: string,
  apply: (t: T, value: string) => void
): IStringFilter<T> => {
  return {
    label,
    component: StringFilterComponent,
    componentProps: {
      label
    },
    apply,
    formatter: (value: string) => {
      return value;
    },
    validator: (dto: unknown) => {
      if (typeof dto !== 'string') {
        throw new Error('Invalid datatype for filter');
      }

      return dto as string;
    }
  };
};

export const enumFilterComponentDataBuilder = <T>(
  label: string,
  apply: (t: T, value: string) => void,
  enumerator: Record<string, string>
): IEnumFilter<T> => {
  return {
    label,
    component: EnumFilterComponent,
    componentProps: {
      label,
      enumValues: getInputOptions(enumerator)
    },
    apply,
    formatter: (value: string) => {
      return value;
    },
    validator: (dto: unknown) => {
      if (typeof dto !== 'string') {
        throw new Error('Invalid datatype for filter');
      }

      return dto as string;
    }
  };
};

export const dateFilterComponentDataBuilder = <T>(
  label: string,
  apply: (t: T, value: Date) => void
): IDateFilter<T> => {
  return {
    label,
    component: DateFilterComponent,
    componentProps: {
      label
    },
    apply,
    formatter: (value: Date) => {
      return value.toLocaleDateString();
    },
    validator: (dto: unknown) => {
      const date = dto as Date;
      if (!date) {
        throw new Error('Invalid datatype for filter');
      }

      return date;
    }
  };
};

export const booleanFilterComponentDataBuilder = <T>(
  label: string,
  apply: (t: T, value: boolean) => void
): IBooleanFilter<T> => {
  return {
    label,
    component: BooleanFilterComponent,
    componentProps: {
      label
    },
    apply,
    formatter: (value: boolean) => {
      return value ? 'true' : 'false';
    },
    validator: (dto: unknown) => {
      if (typeof dto !== 'boolean') {
        throw new Error('Invalid datatype for filter');
      }

      return dto as boolean;
    }
  };
};

export const numberRangeFilterComponentDataBuilder = <T>(
  label: string,
  apply: (t: T, value: NumberRange) => void,
  options?: { min: number; max: number }
): INumberRangeFilter<T> => {
  return {
    label,
    component: NumberRangeFilterComponent,
    componentProps: {
      label,
      min: options?.min ?? 0,
      max: options?.max ?? 100
    },
    apply,
    formatter: (value: NumberRange) => {
      return `${value.minValue} - ${value.maxValue}`;
    },
    validator: (dto: unknown) => {
      const numberRange = dto as NumberRange;
      if (!numberRange) {
        throw new Error('Invalid datatype for filter');
      }

      return numberRange;
    }
  };
};

export type INumberRangeFilter<T> = IFilter<
  T,
  NumberRange,
  INumberRangeComponentProps
>;

export type IEnumFilter<T> = IFilter<T, string, IEnumComponentProps>;
export type IDateFilter<T> = IFilter<T, Date, IBaseFilterProps>;

export type IBooleanFilter<T> = IFilter<T, boolean, IBaseFilterProps>;

export type IStringFilter<T> = IFilter<T, string, IBaseFilterProps>;

export type FilterTypes<T> =
  | INumberRangeFilter<T>
  | IBooleanFilter<T>
  | IStringFilter<T>
  | IDateFilter<T>
  | IEnumFilter<T>;
