var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._l(_vm.columns, function (column, index) {
      return _c("div", { key: index }, [
        _c(
          "div",
          {
            staticClass: "flex items-center p-4 border-b draggable",
            attrs: { draggable: "true" },
            on: {
              dragstart: function ($event) {
                return _vm.onDragStart($event, column)
              },
              dragend: function ($event) {
                return _vm.onDragEnd($event)
              },
              dragover: function ($event) {
                return _vm.onDragOver($event)
              },
              drop: function ($event) {
                return _vm.onDrop($event, column)
              },
            },
          },
          [
            _c("input", {
              attrs: { type: "checkbox" },
              domProps: { checked: column.isSelected },
              on: {
                change: function ($event) {
                  return _vm.selectColumn(index, $event.target.checked)
                },
              },
            }),
            _c("div", { staticClass: "ml-4" }, [
              _vm._v(_vm._s(column.columnTitle)),
            ]),
            _c("div", { staticClass: "flex items-center ml-auto" }, [
              _c(
                "button",
                {
                  staticClass: "w-4 h-4 text-gray-400 focus:outline-none",
                  class: { "text-gray-800": column.isPinned },
                  attrs: { "data-cy": "pinButton" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.togglePinnedColumn(column)
                    },
                  },
                },
                [
                  _c("icon-component", {
                    attrs: { name: "pin", fill: "black" },
                  }),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass:
                    "flex items-center justify-center w-4 h-4 mx-2 focus:outline-none",
                  on: {
                    click: function ($event) {
                      return _vm.swapColumn(column, _vm.columns[index + 1])
                    },
                  },
                },
                [
                  _c("icon-component", {
                    attrs: { name: "arrowDownFilter", width: 7, height: 14 },
                  }),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass:
                    "flex items-center justify-center w-4 h-4 focus:outline-none",
                  on: {
                    click: function ($event) {
                      return _vm.swapColumn(column, _vm.columns[index - 1])
                    },
                  },
                },
                [
                  _c("icon-component", {
                    attrs: { name: "arrowUpFilter", width: 7, height: 14 },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }