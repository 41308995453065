
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconComponent from '@/components/IconComponent.vue';
@Component<EmptyTableMessageComponent>({
  components: {
    IconComponent
  }
})
export default class EmptyTableMessageComponent extends Vue {
  @Prop({
    default: () => {
      return '0 results';
    }
  })
  message?: string;
}
