import { SearchDto } from '@/models/Dtos/common/searchDto';
import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { RootState } from '@/store/types';
import { Store } from 'vuex';

export abstract class TableDataProvider<
  TableDataType,
  EntityType,
  SearchDtoType extends SearchDto<EntityType, unknown>
> {
  protected abstract queryHandler: (
    searchDto: SearchDtoType
  ) => Promise<SearchResponseDto<EntityType>>;
  protected abstract transformer: (entity: EntityType) => TableDataType;

  protected readonly store: Store<RootState>;
  protected readonly baseSearchDto: SearchDto<unknown, never> = {
    limit: 10,
    offset: 0
  };

  constructor(store: Store<RootState>) {
    this.store = store;
  }

  public async searchHandler(
    searchOptions: SearchDtoType
  ): Promise<SearchResponseDto<TableDataType>> {
    const searchDto: SearchDtoType = {
      ...this.baseSearchDto,
      ...searchOptions
    };
    const searchResponse = await this.queryHandler(searchDto);
    const data = searchResponse.data.map((entity) => this.transformer(entity));
    return {
      data,
      resultCount: searchResponse.resultCount
    };
  }
}
