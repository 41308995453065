var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "clickOutside",
          rawName: "v-clickOutside",
          value: _vm.close,
          expression: "close",
        },
      ],
      staticClass: "relative flex",
    },
    [
      _c(
        "button-component",
        { staticClass: "flex items-center", on: { click: _vm.toggleMenu } },
        [
          _c("icon-component", {
            staticClass: "fill-current text-inherit",
            attrs: { name: "filter", width: 14, height: 14 },
          }),
          _c("div", { staticClass: "mx-2 whitespace-nowrap" }, [
            _vm._v("Filter by..."),
          ]),
          _c("icon-component", {
            staticClass: "fill-current text-inherit",
            attrs: { name: "chevronDownThin", width: 14, height: 8 },
          }),
        ],
        1
      ),
      _vm.showMenu
        ? _c(
            "div",
            { staticClass: "absolute z-20 py-2 bg-white border rounded menu" },
            [
              _vm._t("default"),
              _vm._l(_vm.filters, function (filter, index) {
                return _c(
                  _vm.getComponent(filter),
                  _vm._b(
                    {
                      key: index,
                      tag: "component",
                      staticClass: "relative",
                      attrs: { showSubMenu: index === _vm.selectedFilterIndex },
                      on: {
                        clearFilter: function ($event) {
                          return _vm.handleClearFilter(index)
                        },
                        selectFilter: function ($event) {
                          return _vm.handleSelectFilter(index)
                        },
                        submit: (value) => _vm.handleSubmit(value, index),
                      },
                    },
                    "component",
                    _vm.getComponentProps(filter),
                    false
                  )
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }