
import ActionMenu from '@/components/ActionMenus/ActionMenuComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Dropdown from '@/components/Forms/Elements/DropdownComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import NumberRangeComponent from '@/components/NumberRangeComponent.vue';
import { NumberRange } from '@/models/NumberRange';
import clickOutside from '@/util/directives/clickOutside';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component<NumberRangeFilterComponent>({
  components: {
    ActionMenu,
    ButtonComponent,
    Dropdown,
    NumberRangeComponent,
    TextInput,
    IconComponent
  },
  directives: {
    clickOutside
  }
})
export default class NumberRangeFilterComponent extends Vue {
  @Prop()
  label!: string;

  @Prop({ default: 0 })
  readonly min!: number;

  @Prop({ default: 100 })
  readonly max!: number;

  @Prop({ default: false })
  showSubMenu!: string;

  valueString = '';

  minValue = 0;
  maxValue = 0;

  onRangeChange(numberRange: NumberRange): void {
    this.minValue = numberRange.minValue;
    this.maxValue = numberRange.maxValue;
  }

  get sliderMin() {
    return this.minValue.toString();
  }

  set sliderMin(val: string) {
    const parsed: number = parseInt(val);
    if (parsed > this.maxValue) {
      this.maxValue = parsed;
    }
    this.minValue = parsed;
  }

  get sliderMax() {
    return this.maxValue.toString();
  }

  set sliderMax(val: string) {
    const parsed: number = parseInt(val);
    if (parsed < this.minValue) {
      this.minValue = parsed;
    }
    this.maxValue = parsed;
  }

  @Emit('selectFilter')
  selectFilter(): void {
    return;
  }

  @Emit('clearFilter')
  clearFilter(): void {
    return;
  }

  @Emit('submit')
  submit(): NumberRange {
    return {
      minValue: this.minValue,
      maxValue: this.maxValue
    } as NumberRange;
  }
}
