var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "relative flex items-center justify-center h-8 mb-4 mr-4 text-xs text-center bg-white border border-gray-300 border-solid rounded outline-none w-36 whitespace-nowrap",
      attrs: { "data-cy": "tableDropDown" },
    },
    [
      _c(
        "button",
        {
          staticClass: "flex items-center pr-4 text-gray-700",
          attrs: { "data-cy": "tableDropDownSelected" },
          on: { click: _vm.toggle, blur: _vm.onBlur },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selected,
                expression: "selected",
              },
            ],
            staticClass:
              "w-full pl-3 text-sm cursor-default focus:outline-none",
            attrs: {
              "data-cy": "tableDropDownSelectedField",
              placeholder: _vm.placeholder,
              readonly: true,
            },
            domProps: { value: _vm.selected },
            on: {
              blur: _vm.onBlur,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.selected = $event.target.value
              },
            },
          }),
          _c(
            "div",
            {
              staticClass:
                "absolute inset-y-0 right-0 flex items-center justify-center px-2 text-gray-600 pointer-events-none",
            },
            [
              _c("icon-component", {
                staticClass: "absolute right-4",
                attrs: { name: "caretDown", height: 11, width: 11 },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.open,
              expression: "open",
            },
          ],
          staticClass:
            "absolute left-0 z-10 text-center text-gray-700 bg-white border border-gray-300 border-solid rounded shadow cursor-default w-36 top-8",
          attrs: { "data-cy": "tableDropDownList" },
        },
        _vm._l(_vm.itemValues, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass:
                "px-4 py-2 text-sm leading-5 text-left hover:bg-blue-50",
              attrs: { "data-cy": "tableDropDownItem" },
              on: {
                mousedown: function ($event) {
                  return _vm.setDropdownValue(item.value)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.label) + " ")]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }