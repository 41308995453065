var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "w-full h-full" },
    [
      !_vm.loaded
        ? _c(
            "div",
            {
              staticClass:
                "flex flex-col items-center justify-center w-full h-full",
            },
            [
              _c("icon-component", {
                staticClass: "ml-2 animate-pulse",
                attrs: { name: "airForce", width: 60, height: 60 },
              }),
            ],
            1
          )
        : _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }